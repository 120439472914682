import React, {useState} from "react"
import { useTranslation } from 'react-i18next';
import {Row, Modal, Input} from "reactstrap"
import PictureUpload from "../../components/Common/CustomUpload/PictureUpload.js";
import "./index.scss"

const AddParentCategory = (props) => {
  const { t } = useTranslation();
  const [errorStatus, setErrorStatus] = useState(false);
  const [state, setState] = useState({
    id: props.data.id || null,
    title: props.data.title || "",
    title_en: props.data.title_en || "",
    titleValid: false,
    sort_order: props.data.sort_order || 0,
    icon: props.data.icon || "",
    parent_id: null
  });

  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const onUpload = (data) => {
    setState({...state, "icon": data});
  };

  const isValidated = () => {
    let data = {...state};
    data['titleValid'] = state.title.length > 3;
    setState(data);

    if(data['titleValid']) {
      return true
    } else {
      setErrorStatus(true);
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('products_page.update') : t('products_page.add_new')}</h5>
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('products_page.title')} <small>({t('required')})</small></label>
            <Input
              id="title"
              name="title"
              type="text"
              value={state.title}
              className="form-control"
              onChange={event => change(event, 'title')}
              invalid={errorStatus && !state.titleValid}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('products_page.title_en')}</label>
            <Input
              id="title"
              name="title"
              type="text"
              value={state.title_en}
              className="form-control"
              onChange={event => change(event, 'title_en')}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('products_page.order')}</label>
            <Input
              id="order"
              name="order"
              type="text"
              value={state.sort_order}
              className="form-control"
              onChange={event => change(event, 'sort_order')}
            />
          </div>
          <div className="col-md-12 mb-3">
            <PictureUpload imgHolder={state.icon ? state.icon : null} onUpload={(data) => onUpload(data)} />
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddParentCategory
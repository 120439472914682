import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Close, Edit, PlayCircleOutline} from '@mui/icons-material';
import {Table, Button, Card, CardBody, Modal} from "reactstrap"
import Switch from "react-switch";

import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import {LoaderSpinner} from "../../components/Common/Loader";
import {getGreenStepsNewList, createUpdateGreenStep, deleteGreenStep, editGreenStepCategory} from "../../store/actions/greenStep";
import AddGreenStep from "./addGreenStep";
import EditCategory from "./editCategory";
import "./index.scss"

import i18n from "../../i18n";
import Select from "react-select";
import {filterDropdownGreenStepStyles} from "../../helpers/dropdown";
import ReactPlayer from "react-player";

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const [alert, setAlert] = useState(null);
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canEdit = permission.includes('admin_pluses_edit');
  const canDelete = permission.includes('admin_pluses_delete');
  const currentLang = i18n.language || 'is';

  const {loading, greenSteps} = useSelector( state => state.greenStep );
  const [collapse, setCollapse] = useState([1]);


  const [allGreenSteps, setAllGreenSteps] = useState([]);
  const [filterMainCategory, setFilterMainCategory] = useState({label: 'Umhverfi', name: 'Umhverfi', name_en: 'Environment', value: 0});
  const [filterCategory, setFilterCategory] = useState( {label: 'Allir flokkar', name: 'Allir flokkar', name_en: 'All categories', value: 'Allir flokkar'});
  const [filterStep, setFilterStep] = useState(  {label: t('pluses_page.small_step'), label2: 'small_step', value: 'smallCategories'});
  const [filteredData, setFilteredData] = useState(  []);


  const sortingData = (event, type, firstLoad = false, data) => {
    let newData = firstLoad === true ? data : allGreenSteps
    let filterMainCategoryValue = filterMainCategory.value;
    let filterCategoryValue = filterCategory.value;
    let filterStepValue = filterStep.value;

    if(type === 'filterMainCategory') {
      setFilterMainCategory(event);
      filterMainCategoryValue = event.value;
      filterCategoryValue = 'Allir flokkar';
      filterStepValue = 'smallCategories';
      setFilterCategory({label: 'Allir flokkar', name: 'Allir flokkar', name_en: 'All categories', value: 'Allir flokkar'})
      setFilterStep({label: t('pluses_page.small_step'), label2: 'small_step', value: 'smallCategories'})
    } else if(type === 'category') {
      setFilterCategory(event);
      filterCategoryValue = event.value;
    } else if(type === 'step') {
      setFilterStep(event);
      filterStepValue = event.value;
    }

    newData = newData[filterMainCategoryValue][filterStepValue]

    if(filterCategoryValue !== 'Allir flokkar') {
      let findIndex = newData.findIndex(f => f.name === filterCategoryValue)
      newData = findIndex>=0 ? [newData[findIndex]] : []
    }

    setFilteredData(newData);
  }

  const getData = (data) => {
    let allData = [];

    data.forEach(category => {
      let bigData = [];
      let smallData = [];
      let categoryNames = [];

      category.children_category.forEach(item => {
        if(item.icon !== null) {
          bigData.push({
            "id":  item.id,
            "name":  item.name,
            "name_en":  item.name_en,
            "sort_order":  item.sort_order,
            "new_green_steps": item.new_green_steps
          })
        } else {
          let tempSmall = [];
          item.categories.length>0 ? item.categories.forEach(c => tempSmall.push(...c.new_green_steps)) : tempSmall.push(...item.new_green_steps)
          tempSmall.length>0 && smallData.push({
            "id":  item.id,
            "name":  item.name,
            "name_en":  item.name_en,
            "sort_order":  item.sort_order,
            "new_green_steps": tempSmall
          })
        }

        if(categoryNames.findIndex(f => f.name === item.name) === -1) {
          categoryNames.push({label: item.name, name: item.name, name_en: item.name_en, value: item.name});
        }
      });

      allData.push({
        "id":  category.id,
        "name":  category.name,
        "name_en":  category.name_en,
        "bigCategories": bigData,
        "smallCategories": smallData,
        "categoryNames": categoryNames,
        "sort_order":  category.sort_order,
      })
    });

    setAllGreenSteps(allData)
    sortingData('', '', true, allData);
  };


  useEffect(() => {
    dispatch(getGreenStepsNewList());
  }, []);

  useEffect(() => {
    if(greenSteps && greenSteps.results) {
      getData(greenSteps.results);
    }
  }, [greenSteps]);

  if (loading) return (<LoaderSpinner/>);

  const onSubmit = (data, update) => {
    dispatch(createUpdateGreenStep(data, update));
  };

  const onEditCategory = (data, update) => {
    dispatch(editGreenStepCategory(data, update));
  };

  const htmlAlert = (data, subCategory, mainCategoryIndex, subCategoryIndex, childName) => {
    setAlert(
      <AddGreenStep data={data} subCategory={subCategory} childName={childName} parent_index={mainCategoryIndex} child_index={subCategoryIndex} onCancel={() => setAlert(null)} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const editCategory = (data) => {
    setAlert(
      <EditCategory data={data} onCancel={() => setAlert(null)} onSubmit={(data, update) => onEditCategory(data, update)}/>
    );
  };

  const handleVisible = (item) => {
    dispatch(createUpdateGreenStep({...item, visible: !item.visible}, true));
  }


  const showVideo = (item) => {
    setAlert(
      <Modal isOpen={true} centered={true} size="lg" toggle={() => setAlert('')} className="greenStepVideoModal">
        <div className="modal-body">
          <ReactPlayer
            className="videoFrame"
            url={item.video}
            playing={true}
            controls
          />
          <div onClick={() => setAlert('')} className="videoClose" data-dismiss="modal">
            <Close/>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="page-content greenStepAdminPage">
      {alert}

      <div className="filters">
        <div className="filter level">
          <span>{t('pluses_page.categories')}</span>
          <Select
            isSearchable={false}
            value={{
              ...filterMainCategory,
              label: filterMainCategory["name_" + currentLang] ? filterMainCategory["name_" + currentLang] : filterMainCategory.name
            }}
            onChange={(event) => sortingData(event, 'filterMainCategory')}
            styles={filterDropdownGreenStepStyles()}
            options={
              allGreenSteps.map((item, index) => {
                return {...item, label: item["name_" + currentLang] ? item["name_" + currentLang] : item.name, value: index}
              })
            }
          />
        </div>
        <div className="filter category">
          <span>{t('pluses_page.subcategories')}</span>
          <Select
            isSearchable={false}
            value={{
              ...filterCategory,
              label: filterCategory["name_" + currentLang] ? filterCategory["name_" + currentLang] : filterCategory.name
            }}
            onChange={(event) => sortingData(event, 'category')}
            styles={filterDropdownGreenStepStyles()}
            options={
              allGreenSteps.length>0 ? [
                {label: 'Allir flokkar', name: 'Allir flokkar', name_en: 'All categories', value: 'Allir flokkar'},
                ...allGreenSteps[filterMainCategory.value].categoryNames.map((item) => {
                  return {...item, label: item["name_" + currentLang] ? item["name_" + currentLang] : item.name}
                })
              ]: []
            }
          />
        </div>
        <div className="filter step">
          <span>{t('pluses_page.actionsSize')}</span>
          <Select
            isSearchable={false}
            value={filterStep ? {...filterStep, label: t('pluses_page.' + filterStep.label2)} : {}}
            onChange={(event) =>  sortingData(event, 'step')}
            styles={filterDropdownGreenStepStyles()}
            options={[
              {label: t('pluses_page.big_step'), label2: 'big_step', value: 'bigCategories'},
              {label: t('pluses_page.small_step'), label2: 'small_step', value: 'smallCategories'}
            ]}
          />
        </div>
        <div class="filter addNew">
          <Button
            color="success"
            onClick={htmlAlert}>
            {t('pluses_page.add_new2')}
          </Button>
        </div>
      </div>


      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>{t('pluses_page.title')}</th>
                  <th width="100">{t('pluses_page.irrelevant')}</th>
                  <th>Video</th>
                  <th>Score</th>
                  <th>Visible</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((subCategory, subCategoryIndex) => {
                  return [
                    <tr key={subCategoryIndex + 'parent'} className="table-success">
                      <td>{subCategory["name_" + currentLang] ? subCategory["name_" + currentLang] : subCategory.name}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {canEdit &&
                          <Button
                            key={subCategory.id + 'edit_sub_category_btn'}
                            color="success"
                            size="sm"
                            style={{marginRight: '10px'}}
                            onClick={() => editCategory(subCategory)}
                          >
                            <Edit style={{fontSize: '12px'}}/>
                          </Button>
                        }
                      </td>
                    </tr>,

                    !subCategory.new_green_steps ? '' :
                      subCategory.new_green_steps.map((item, index) => {
                        return <tr key={index + 'child'}>
                          <td>{item["title_" + currentLang] ? item["title_" + currentLang] : item.title}</td>
                          <td>{item.irrelevant ? t('yes') : ''}</td>
                          <td>
                            {item.video &&
                              <div className="showVideo" onClick={() => showVideo(item)}>
                                <PlayCircleOutline/>
                              </div>
                            }
                          </td>
                          <td>{item.score}</td>
                          <td>
                            <Switch
                              key={item.id + 'Switch'}
                              uncheckedIcon={<div className="swithStyle"> {" "} No </div>}
                              checkedIcon={<div className="swithStyle">{" "}Yes</div>}
                              onColor="#009369"
                              className="form-label irrelevant_checkbox"
                              onChange={() => {
                                handleVisible(item)
                              }}
                              checked={item.visible}
                            />
                          </td>
                          <td width="120">
                            {canEdit &&
                              <Button
                                key={item.id + 'edit'}
                                color="success"
                                size="sm"
                                style={{marginRight: '10px'}}
                                onClick={() => htmlAlert(item, subCategory.name)}
                              >
                                <Edit style={{fontSize: '12px'}}/>
                              </Button>
                            }
                            {canDelete &&
                              <DeleteButton key={item.id + 'delete'} action={() => dispatch(deleteGreenStep(item.id))}/>
                            }
                          </td>
                        </tr>
                      }),


                  ];
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>




    </div>
  )
};

export default Index

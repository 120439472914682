import React, {useEffect, useState} from "react"
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Label, Input} from "reactstrap"
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import Switch from "react-switch";

import {getGreenStepsCategories} from "../../store/actions/greenStep";
import {LoaderSpinner} from "../../components/Common/Loader";
import {customDropdownStyles} from "../../helpers/dropdown";
import "./index.scss"
import {getProductCategories} from "../../store/actions/products";
import {getServiceCategories} from "../../store/actions/service";

const companySizeOptions = [
  {label: '1', value: 1}, {label: '2', value: 2},
  {label: '3', value: 3}
];

const AddGreenStep = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {loadingCategories, greenStepsCategories} = useSelector( state => state.greenStep );
  const {categories, loadingCategories:loadingProductCategories} = useSelector( state => state.product);
  const {categories:serviceCategories, loadingCategories:loadingServiceCategories} = useSelector( state => state.service);
  const [state, setState] = useState({
    ...props.data,
    id: props.data.id || null,
    title: props.data.title || "",
    title_en: props.data.title_en || "",
    titleState: false,
    description: props.data.description || "",
    description_en: props.data.description_en || "",
    pdf_description: props.data.pdf_description || "",
    pdf_description2: props.data.pdf_description2 || "",
    pdf_description_en: props.data.pdf_description_en || "",
    pdf_description2_en: props.data.pdf_description2_en || "",
    slider_description: props.data.slider_description || "",
    slider_description_en: props.data.slider_description_en || "",
    sort_order: props.data.sort_order || "",
    new_green_step_category_id: props.data.new_green_step_category_id || '',
    green_step_category_name: props.subCategory || '',
    new_green_step_category_id2: props.parent_index>= 0 && props.child_index >= 0 ? props.data.new_green_step_category_id : '',
    green_step_category_name2: props.childName || '',
    child_index: props.child_index >= 0 ? props.child_index : '',
    parent_index: props.parent_index>= 0 ? props.parent_index : '',
    main_category: props.data.main_category || null,
    score: props.data.score || null,
    selectError: false,
    irrelevant: props.data.irrelevant || false,
    type: props.data.irrelevant || null,
    quotation: props.data.quotation || null,
    company_size1: props.data.company_size1 || 0,
    company_size2: props.data.company_size2 || 0,
    company_size3: props.data.company_size3 || 0,
    company_size4: props.data.company_size4 || 0,
    visible: props.data.visible || true,
    video: props.data.video || "",
    product_category_id: props.data.product_category_id || null,
    addProductCategory: props.data.product_category_id !== null,
    service_category_id: props.data.service_category_id || null,
    addServiceCategory: props.data.service_category_id !== null,
  });
  const [productCategoriesList, setProductCategoriesList] = useState(null);
  const [serviceCategoriesList, setServiceCategoriesList] = useState(null);


  useEffect(() => {
    greenStepsCategories.length === 0 && dispatch(getGreenStepsCategories());
    categories.length === 0 && dispatch(getProductCategories());
    serviceCategories.length === 0 && dispatch(getServiceCategories());
  }, []);

  useEffect(() => {
    if(categories && categories.results) {
      let list = [];
      categories.results.forEach((item) => {
        list.push({label: item.title, value: item.id})
      });
      setProductCategoriesList(list);
    }
  }, [categories]);

  useEffect(() => {
    if(serviceCategories && serviceCategories.results) {
      let list = [];
      serviceCategories.results.forEach((item) => {
        list.push({label: item.title, value: item.id})
      });
      setServiceCategoriesList(list);
    }
  }, [serviceCategories]);

  if (loadingCategories || loadingProductCategories || loadingServiceCategories) return(
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{t('pluses_page.update_category')}</h5>
      </div>
      <div className="modal-body">
        <LoaderSpinner/>
      </div>
    </Modal>
  );

  const handleSelectCategory = (event) => {
    setState({
      ...state,
      'selectError': true,
      'new_green_step_category_id': event.value,
      'green_step_category_name': event.label,
      'child_index': event.index,
      'parent_index': event.parentIndex,
      'main_category': event.parentId
    });
  };

  const handleSelectProductCategory = (event) => {
    setState({
      ...state,
      'product_category_id': event.value
    });
  };

  const handleSelectServiceCategory = (event) => {
    setState({
      ...state,
      'service_category_id': event.value
    });
  };

  const handleSelectSubCategory = (event) => {
    setState({
      ...state,
      'selectError': true,
      'new_green_step_category_id2': event.value,
      'green_step_category_name2': event.label
    });
  };

  const verifyLength = (value, length) => {
    return value.length < length;
  };

  const isValidated = () => {
    if (
      state.title.length > 3  &&
      state.new_green_step_category_id &&
      state.company_size1 > 0 &&
      state.company_size2 > 0 &&
      state.company_size3 > 0 &&
      state.company_size4 > 0
    ) {
      return true;
    } else {
      setState({
        ...state,
        selectError: !state.new_green_step_category_id,
        titleState: state.title.length < 3,
        company_size1Error: state.company_size1 === 0,
        company_size2Error: state.company_size2 === 0,
        company_size3Error: state.company_size3 === 0,
        company_size4Error: state.company_size4 === 0
      });
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      if(state.new_green_step_category_id2 !== '' && state.green_step_category_name2 !== '') {
        let newState = state;
        newState["new_green_step_category_id"] = newState.new_green_step_category_id2;
        newState["green_step_category_name"] = newState.green_step_category_name2;
        props.onSubmit(newState, !!newState.id);
      } else {
        if(state.product_category_id === 0 || state.addProductCategory === false) {
          state["product_category_id"] = null;
        }
        if(state.service_category_id === 0 || state.addServiceCategory === false) {
          state["service_category_id"] = null;
        }
        props.onSubmit(state, !!state.id);
      }
      props.onCancel();
    }
  };

  const change = (event, stateName, type, stateNameEqualTo) => {
    let status = false;
    if(type === 'length') {
      status = verifyLength(event.target.value, stateNameEqualTo);
    }
    setState({
      ...state,
      [stateName]: event.target.value,
      [stateName+'State']: status,
    });
  };

  const handleSelectCompanySize = (event, stateName) => {
    setState({
      ...state,
      [stateName]: event.value
    });
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} size="lg" toggle={() => {onCancel()}} backdrop={"static"} style={{maxWidth: '1100px', width: '100%'}}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('pluses_page.update') : t('pluses_page.add_new')}</h5>
      </div>
      <div className="modal-body greenStepAdminPageAdd">
        <div className="row">
          <div className="col col-3">
            <Label className="col-form-label">{t('pluses_page.categories')}</Label>
            <Select
              key={'gs_categories'}
              isSearchable={false}
              value={{label: state.green_step_category_name, value: state.new_green_step_category_id}}
              placeholder={t('plan_page.select')}
              styles={customDropdownStyles()}
              className={`react-select ${state.selectError && 'has-error'}`}
              onChange={(event) => handleSelectCategory(event)}
              options={
                greenStepsCategories.results && greenStepsCategories.results.map((item, itemIndex) => {
                  return {
                    label: item.name,
                    options: item.children_category.map((item2, item2Index) => {
                      return {
                        label: item2.name,
                        value: item2.id,
                        index: item2Index,
                        parentId: item.id,
                        parentIndex: itemIndex
                      }
                    })
                  }
                })
              }
            />
          </div>
          <div className="col col-4">
            <Label className="col-form-label">Sub categories:</Label>
            {(greenStepsCategories.results && state.parent_index !== '' && state.child_index !== '' &&
              greenStepsCategories.results[state.parent_index].children_category[state.child_index].categories.length > 0) ?
              <Select
                key={'gs_sub_categories'}
                isSearchable={false}
                value={{label: state.green_step_category_name2, value: state.new_green_step_category_id2}}
                placeholder={t('plan_page.select')}
                className={`react-select`}
                styles={customDropdownStyles()}
                onChange={(event) => handleSelectSubCategory(event)}
                options={
                  greenStepsCategories.results[state.parent_index].children_category[state.child_index].categories.map(item => {
                    return {label: item.name, value: item.id}
                  })
                }
              /> :
              <Select styles={customDropdownStyles()} isDisabled={true}/>
            }
          </div>

          <div className="col col-2">
            <Label className="col-form-label">Score</Label>
            <Input
              name="action_input"
              type="text"
              className="form-control"
              value={state.score}
              onChange={event => change(event, 'score')}
            />
          </div>

          <div className="col col-2">
            <Label className="col-form-label"> {t('pluses_page.sort_order')}</Label>
            <Input
              name="action_input"
              type="text"
              className="form-control"
              value={state.sort_order}
              onChange={event => change(event, 'sort_order')}
            />
          </div>

          <div className="col col-1">
            <label className="col-form-label">{t('pluses_page.irrelevant_admin')}</label>
            <Switch
              uncheckedIcon={<div className="swithStyle"> {" "} No </div>}
              checkedIcon={<div className="swithStyle">{" "}Yes</div>}
              onColor="#009369"
              className="form-label irrelevant_checkbox"
              onChange={() => {
                setState({...state, 'irrelevant': !state.irrelevant})
              }}
              checked={state.irrelevant}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-6">
            <Label className="col-form-label">{t('pluses_page.title')} <small>({t('required')})</small></Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'title', "length", 3)
              }}
              value={state.title}
              minRows={2}
              maxRows={6}
              className={state.titleState ? 'error' : ''}
            />
          </div>
          <div className="col col-6">
            <Label className="col-form-label">{t('pluses_page.title_en')}</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'title_en', "length", 3)
              }}
              value={state.title_en}
              minRows={2}
              maxRows={6}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-6">
            <Label className="col-form-label">{t('pluses_page.description')}</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'description', "length", 3)
              }}
              value={state.description}
              minRows={2}
              maxRows={6}
            />
          </div>
          <div className="col col-6">
            <Label className="col-form-label">{t('pluses_page.description_en')}</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'description_en', "length", 3)
              }}
              value={state.description_en}
              minRows={2}
              maxRows={6}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-3">
            <Label className="col-form-label">PDF {t('pluses_page.description')}</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'pdf_description')
              }}
              value={state.pdf_description}
              minRows={2}
              maxRows={5}
            />
          </div>
          <div className="col col-3">
            <Label className="col-form-label">PDF {t('pluses_page.description_en')}</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'pdf_description_en')
              }}
              value={state.pdf_description_en}
              minRows={2}
              maxRows={5}
            />
          </div>

          <div className="col col-3">
            <Label className="col-form-label">PDF {t('pluses_page.description')} 2</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'pdf_description2')
              }}
              value={state.pdf_description2}
              minRows={2}
              maxRows={5}
            />
          </div>
          <div className="col col-3">
            <Label className="col-form-label">PDF {t('pluses_page.description_en')} 2</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'pdf_description2_en')
              }}
              value={state.pdf_description2_en}
              minRows={2}
              maxRows={5}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-3">
            <Label className="col-form-label">{t('Slider description')}</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'slider_description')
              }}
              value={state.slider_description}
              minRows={2}
              maxRows={5}
            />
          </div>
          <div className="col col-3">
            <Label className="col-form-label">{t('Slider description en')}</Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'slider_description_en')
              }}
              value={state.slider_description_en}
              minRows={2}
              maxRows={5}
            />
          </div>
          <div className="col col-6">
            <Label className="col-form-label"> Quotations </Label>
            <TextareaAutosize
              onChange={(e) => {
                change(e, 'quotation')
              }}
              value={state.quotation}
              minRows={2}
              maxRows={5}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-2">
            <Label className="col-form-label">Company size 1</Label>
            <Select
              key={'gs_company_size1'}
              isSearchable={false}
              value={state.company_size1 > 0 ? companySizeOptions.find(f => f.value === state.company_size1) : {}}
              placeholder={t('plan_page.select')}
              styles={customDropdownStyles()}
              className={`react-select ${state.company_size1Error && 'has-error'}`}
              onChange={(event) => handleSelectCompanySize(event, 'company_size1')}
              options={companySizeOptions}
            />
          </div>

          <div className="col col-2">
            <Label className="col-form-label">Company size 2</Label>
            <Select
              key={'gs_company_size2'}
              isSearchable={false}
              value={state.company_size2 > 0 ? companySizeOptions.find(f => f.value === state.company_size2) : {}}
              placeholder={t('plan_page.select')}
              styles={customDropdownStyles()}
              className={`react-select ${state.company_size2Error && 'has-error'}`}
              onChange={(event) => handleSelectCompanySize(event, 'company_size2')}
              options={companySizeOptions}
            />
          </div>

          <div className="col col-2">
            <Label className="col-form-label">Company size 3</Label>
            <Select
              key={'gs_company_size3'}
              isSearchable={false}
              value={state.company_size3 > 0 ? companySizeOptions.find(f => f.value === state.company_size3) : {}}
              placeholder={t('plan_page.select')}
              styles={customDropdownStyles()}
              className={`react-select ${state.company_size3Error && 'has-error'}`}
              onChange={(event) => handleSelectCompanySize(event, 'company_size3')}
              options={companySizeOptions}
            />
          </div>

          <div className="col col-2">
            <Label className="col-form-label">Company size 4</Label>
            <Select
              key={'gs_company_size4'}
              isSearchable={false}
              value={state.company_size4 > 0 ? companySizeOptions.find(f => f.value === state.company_size4) : {}}
              placeholder={t('plan_page.select')}
              styles={customDropdownStyles()}
              className={`react-select ${state.company_size4Error && 'has-error'}`}
              onChange={(event) => handleSelectCompanySize(event, 'company_size4')}
              options={companySizeOptions}
            />
          </div>

          <div className="col col-4">
            <Label className="col-form-label">Video</Label>
            <Input
              name="action_input"
              type="text"
              className="form-control"
              value={state.video}
              onChange={event => change(event, 'video')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-1">
            <label className="col-form-label">{t('product-categories')}</label>
            <Switch
              uncheckedIcon={<div className="swithStyle"> {" "} No </div>}
              checkedIcon={<div className="swithStyle">{" "}Yes</div>}
              onColor="#009369"
              className="form-label irrelevant_checkbox"
              onChange={() => {
                setState({...state, 'addProductCategory': !state.addProductCategory})
              }}
              checked={state.addProductCategory}
            />
          </div>

          {state.addProductCategory &&
            <div className="col col-3">
              <Label className="col-form-label">{t('product-categories')}</Label>
              <Select
                key={'gs_product_categories'}
                isSearchable={false}
                value={state.product_category_id && productCategoriesList && productCategoriesList.find(f => f.value === state.product_category_id)}
                placeholder={t('plan_page.select')}
                styles={customDropdownStyles()}
                className={`react-select`}
                onChange={(event) => handleSelectProductCategory(event)}
                options={productCategoriesList}
              />
            </div>
          }

          <div class="col col-2"></div>

          <div className="col col-2">
            <label className="col-form-label">{t('service-categories')}</label>
            <Switch
              uncheckedIcon={<div className="swithStyle"> {" "} No </div>}
              checkedIcon={<div className="swithStyle">{" "}Yes</div>}
              onColor="#009369"
              className="form-label irrelevant_checkbox"
              onChange={() => {
                setState({...state, 'addServiceCategory': !state.addServiceCategory})
              }}
              checked={state.addServiceCategory}
            />
          </div>

          {state.addServiceCategory &&
            <div className="col col-3">
              <Label className="col-form-label">{t('service-categories')}</Label>
              <Select
                key={'gs_service_categories'}
                isSearchable={false}
                value={state.service_category_id && serviceCategoriesList && serviceCategoriesList.find(f => f.value === state.service_category_id)}
                placeholder={t('plan_page.select')}
                styles={customDropdownStyles()}
                className={`react-select`}
                onChange={(event) => handleSelectServiceCategory(event)}
                options={serviceCategoriesList}
              />
            </div>
          }
        </div>

        <br/>
      </div>

      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddGreenStep
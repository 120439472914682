import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { useTranslation } from 'react-i18next';
import {Check,} from "@mui/icons-material";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {createCompanyGreenStep, updateCompanyGreenStep} from "../../../../store/actions/greenStep";


const GreenStep220 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [id, setId] = useState(0);
  const [state, setState] = useState({
    'organic': false,
    'paper': false,
    'cardboard': false,
    'plastic': false,
    'glass': false,
    'deposit': false,
    'textile': false,
    'metals': false,
    'electronics': false,
  });

  useEffect(() => {
    if(companyGreenStep) {
      let comment = JSON.parse(companyGreenStep.comment);
      setState(comment[0]);
      setId(companyGreenStep.id);
    }
  }, [props.companyGreenStep]);

  const onChangeState = (item) => {
    let copyState = {...state};
    copyState[item] = !copyState[item];
    setState(copyState);
  };


  const handleSave = () => {
    if(Object.values(state).every(v => v === false)) {
      return
    }

    let score = 0;
    score += state.organic ? 2 : 0;
    score += state.paper ? 1 : 0;
    score += state.cardboard ? 1 : 0;
    score += state.plastic ? 1 : 0;
    score += state.glass ? 1 : 0;
    score += state.deposit ? 1 : 0;
    score += state.textile ? 1 : 0;
    score += state.metals ? 1 : 0;
    score += state.electronics ? 1 : 0;

    let commentsData = [{
      ...state,
      "comment": `Fyrirtæki flokkar úrgang sinn í eftirfarandi flokka. Athugið að ekki allir flokkar eiga við hjá öllum rekstri.`,
      "comment_en": `A company classifies its waste into the following categories. Note that not all categories apply to all operations.`,
      "user_id": userData.user.id,
      "name": userData.user.name,
      "date": HelperGetCurrentDate(),
      "customComment": true
    }];

    let data = {
      "id": id,
      "new_green_step_id": item.id,
      "slider_score": Math.round(score),
      "slider_score_percentage": Math.round(score),
      "comment": commentsData,
      "irrelevant": false,
      "reason": [],
      "pdf": null,
      "pdf_name": null,
      "link": null
    };

    if(id > 0) {
      dispatch(updateCompanyGreenStep(data, userData.companyId, item.main_category-1));
    } else {
      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
    }

  }

  return (
    <div className="customGreenStep220">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="companyCompletedGroup">
        <div className="companyCompleted checkBox" onClick={() => onChangeState('organic')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.organic}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Lífrænan úrgang' : 'Organic waste'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('paper')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.paper}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Pappír' : 'Paper'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('cardboard')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.cardboard}`}><Check/></div>
          <div className="companyCompletedTitle"> {currentLang === 'is' ? 'Pappa' : 'Cardboard'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('plastic')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.plastic}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Plast' : 'Plastic'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('glass')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.glass}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Gler' : 'Glass'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('deposit')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.deposit}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Skilagjaldsskyldar umbúðir' : 'Deposit beverage containers'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('textile')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.textile}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Textíl' : 'Textile'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('metals')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.metals}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Málma' : 'Metals'}</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('electronics')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.electronics}`}><Check/></div>
          <div className="companyCompletedTitle">{currentLang === 'is' ? 'Spilliefni og raftæki' : 'Hazardous waste and electronics'}</div>
        </div>
      </div>

      <div className="saveRow">
        <div className="saveBtn categoryBgColor" onClick={() => handleSave()}>
          {t('save')}
        </div>
      </div>

    </div>
  )
};

export default GreenStep220
import * as actionTypes from '../actionTypes';

const initialState = {
  loadingCategories: true,
  loadingCompanyServices: true,
  companyServices: [],
  categories: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_COMPANY_SERVICES_START:
      return  {
        ...state,
        loadingCompanyServices: true
      };
    case actionTypes.GET_COMPANY_SERVICES_SUCCESS:
      return {
        ...state,
        companyServices: action.data,
        loadingCompanyServices: false
      };
    case actionTypes.GET_COMPANY_SERVICES_FAIL:
      return  {
        ...state,
        loadingCompanyServices: false
      };


    case actionTypes.GET_SERVICE_CATEGORIES_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.GET_SERVICE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.data,
        loadingCategories: false
      };
    case actionTypes.GET_SERVICE_CATEGORIES_FAIL:
      return  {
        ...state,
        loadingCategories: false
      };

    case actionTypes.CREATE_SERVICE_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.CREATE_SERVICE_CATEGORY_SUCCESS:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_SERVICE_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.UPDATE_SERVICE_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.UPDATE_SERVICE_CATEGORY_SUCCESS:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_SERVICE_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.DELETE_SERVICE_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.DELETE_SERVICE_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingCategories: false
      };
    case actionTypes.DELETE_SERVICE_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false
      };


    default:
      return state;
  }
}

import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getGreenStepsNew = (companyId = null) => async dispatch => {
  dispatch({type: actionTypes.GET_GREEN_STEPS_NEW_START});
  try {
    const res = await axios.get(`/new_green_step_category?company_id=${companyId}`);
    dispatch({type: actionTypes.GET_GREEN_STEPS_NEW_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_GREEN_STEPS_NEW_FAIL});
  }
};

export const getGreenStepsNewList = () => async dispatch => {
  dispatch({type: actionTypes.GET_GREEN_STEPS_NEW_START});
  try {
    const res = await axios.get(`/new_green_step_category?admin=true`);
    dispatch({type: actionTypes.GET_GREEN_STEPS_NEW_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_GREEN_STEPS_NEW_FAIL});
  }
};

export const getGreenStepTips = () => async dispatch => {
  dispatch({type: actionTypes.GET_TIPS_START});
  try {
    const res = await axios.get('/tip');
    dispatch({type: actionTypes.GET_TIPS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_TIPS_FAIL});
  }
};

export const getGreenStepsRegistration = () => async dispatch => {
  dispatch({type: actionTypes.GET_GREEN_STEPS_REGISTRATION_START});
  try {
    const res = await axios.get(`/registration/get_green_steps`);
    dispatch({type: actionTypes.GET_GREEN_STEPS_REGISTRATION_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_GREEN_STEPS_REGISTRATION_FAIL});
  }
};


export const getGreenStepsCategories = () => async dispatch => {
  dispatch({type: actionTypes.GET_GREEN_STEPS_CATEGORIES_START});
  try {
    const res = await axios.get('/new_green_step_category');
    dispatch({type: actionTypes.GET_GREEN_STEPS_CATEGORIES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_GREEN_STEPS_CATEGORIES_FAIL});
  }
};


export const createUpdateGreenStep = (data, update, companyId) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_GREEN_STEP_START : actionTypes.CREATE_GREEN_STEP_START});
  try {
    const res = await axios({
      url: update ? '/new_green_step/' + data.id : '/new_green_step',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    dispatch({type: update ? actionTypes.UPDATE_GREEN_STEP_SUCCESS : actionTypes.CREATE_GREEN_STEP_SUCCESS, data: res.data});
    dispatch(getGreenStepsNewList());
  } catch (error) {
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: update ? actionTypes.UPDATE_GREEN_STEP_FAIL : actionTypes.CREATE_GREEN_STEP_FAIL, data: errorMessage});
    }
  }
};

export const deleteGreenStep = (id) => async dispatch => {
  dispatch({type: actionTypes.DELETE_GREEN_STEP_START});
  try {
    const res = await axios.delete('/new_green_step/' + id);
    dispatch({type: actionTypes.DELETE_GREEN_STEP_SUCCESS, data: res.data});
    dispatch(getGreenStepsNewList());
  } catch (error) {
    dispatch({type: actionTypes.DELETE_GREEN_STEP_FAIL});
  }
};


export const editGreenStepCategory = (data) => async dispatch => {
  dispatch({type: actionTypes.EDIT_GREEN_STEP_CATEGORY_START});
  try {
    const res = await axios({
      url: '/new_green_step_category/' + data.id,
      method: 'PUT',
      data: {...data}
    });
    dispatch({type: actionTypes.EDIT_GREEN_STEP_CATEGORY_SUCCESS, data: res.data});
    dispatch(getGreenStepsNewList());
  } catch (error) {
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.EDIT_GREEN_STEP_CATEGORY_FAIL, data: errorMessage});
    }
  }
};



export const createCompanyGreenStep = (data, companyId, activeCategory, initAdd = false) => async dispatch => {
  dispatch({type: actionTypes.CREATE_COMPANY_GREEN_STEP_START});
  try {
    const res = await axios({
      url: '/new_company_green_step',
      method: 'POST',
      data: {
        ...data,
        "company_id": companyId,
      }
    });
    dispatch({
      type: actionTypes.CREATE_COMPANY_GREEN_STEP_SUCCESS,
      data: res.data,
      activeCategory: activeCategory,
      initAdd: initAdd
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð vistuð!', style:'success'});
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Í augnablikinu er ekki hægt að hlaða upp skjali sem er stærra en 1 MB', style:'error'});
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.CREATE_COMPANY_GREEN_STEP_FAIL, data: errorMessage});
    }
  }
};

export const updateCompanyGreenStep = (data, companyId, activeCategory, updatedFromDashboard = false) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_COMPANY_GREEN_STEP_START});
  try {
    const res = await axios({
      url: '/new_company_green_step/'+data.id,
      method: 'PUT',
      data: {
        ...data,
        "company_id": companyId,
      }
    });
    if(updatedFromDashboard) {
      dispatch({
        type: actionTypes.UPDATE_COMPANY_GREEN_STEP_FROM_DASHBOARD_SUCCESS,
        data: res.data,
        activeCategory: activeCategory,
      });
    } else {
      dispatch({
        type: actionTypes.UPDATE_COMPANY_GREEN_STEP_SUCCESS,
        data: res.data,
        activeCategory: activeCategory,
      });
    }
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð vistuð!', style:'success'});
  } catch (error) {
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.UPDATE_COMPANY_GREEN_STEP_FAIL, data: errorMessage});
    }
  }
};

export const markCategoryAsIrrelevant = (subCategoryId, companyId) => async dispatch => {
  dispatch({type: actionTypes.MARK_CATEGORY_AS_IRRELEVANT_START});
  try {
    const res = await axios({
      url: '/new_company_green_step/mark_category_as_irrelevant',
      method: 'POST',
      data: {
        "sub_category_id": subCategoryId,
        "company_id": companyId,
        "irrelevant": true
      }
    });
    dispatch(getGreenStepsNew(companyId));
    dispatch({type: actionTypes.MARK_CATEGORY_AS_IRRELEVANT_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð vistuð!', style:'success'});
  } catch (error) {
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.MARK_CATEGORY_AS_IRRELEVANT_FAIL, data: errorMessage});
    }
  }
};

export const removeCategoryAsIrrelevant = (subCategoryId, companyId) => async dispatch => {
  dispatch({type: actionTypes.REMOVE_CATEGORY_AS_IRRELEVANT_START});
  try {
    const res = await axios({
      url: '/new_company_green_step/remove_category_as_irrelevant',
      method: 'POST',
      data: {
        "sub_category_id": subCategoryId,
        "company_id": companyId
      }
    });
    dispatch(getGreenStepsNew(companyId));
    dispatch({type: actionTypes.REMOVE_CATEGORY_AS_IRRELEVANT_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð vistuð!', style:'success'});
  } catch (error) {
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.REMOVE_CATEGORY_AS_IRRELEVANT_FAIL, data: errorMessage});
    }
  }
};

export const editComment = (id, comment, categoryId, type) => async dispatch => {
  dispatch({type: actionTypes.EDIT_COMMENT_START});
  try {
    const res = await axios({
      url: '/new_company_green_step/edit_comment/'+id,
      method: 'PUT',
      data: {
        [type]: comment
      }
    });

    dispatch({
      type: actionTypes.EDIT_COMMENT_SUCCESS,
      data: res.data,
      activeCategory: categoryId
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð vistuð!', style:'success'});
  } catch (error) {
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.EDIT_COMMENT_FAIL, data: errorMessage});
    }
  }
};

export const editPdf = (id, categoryId, pdf, pdfName) => async dispatch => {
  dispatch({type: actionTypes.EDIT_GREEN_STEP_PDF_START});
  try {
    const res = await axios({
      url: '/new_company_green_step/edit_pdf/'+id,
      method: 'PUT',
      data: {
        "pdf": pdf,
        "pdf_name": pdfName,
      }
    });

    dispatch({
      type: actionTypes.EDIT_GREEN_STEP_PDF_SUCCESS,
      data: res.data,
      activeCategory: categoryId
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð vistuð!', style:'success'});
  } catch (error) {
    if(error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.EDIT_GREEN_STEP_PDF_FAIL, data: errorMessage});
    }
  }
};


export const deleteCompanyGreenStep = (id, categoryId, deleteType = 'delete', initDelete = false, updatedFromDashboard = false) => async dispatch => {
  dispatch({type: actionTypes.DELETE_COMPANY_GREEN_STEP_START});
  try {
    const res = await axios.delete('/new_company_green_step/' + id);
    if(updatedFromDashboard) {
      dispatch({
        type: actionTypes.DELETE_COMPANY_GREEN_STEP_FROM_DASHBOARD_SUCCESS,
        data: res.data,
        activeCategory: categoryId,
        deleteType: deleteType,
        initDelete: initDelete
      });
    } else {
      dispatch({
        type: actionTypes.DELETE_COMPANY_GREEN_STEP_SUCCESS,
        data: res.data,
        activeCategory: categoryId,
        deleteType: deleteType,
        initDelete: initDelete
      });
    }
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð vistuð!', style:'success'});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_COMPANY_GREEN_STEP_FAIL});
  }
};


export const getUnGoals = () => async dispatch => {
  dispatch({type: actionTypes.GET_UN_GOALS_START});
  try {
    const res = await axios.get(`/un_goal`);
    dispatch({type: actionTypes.GET_UN_GOALS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_UN_GOALS_FAIL});
  }
};

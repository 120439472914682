import * as actionTypes from '../actionTypes';

const initialState = {
  loadingCategories: true,
  loadingCompanyProducts: true,
  companyProducts: [],
  categories: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_COMPANY_PRODUCTS_START:
      return  {
        ...state,
        loadingCompanyProducts: true
      };
    case actionTypes.GET_COMPANY_PRODUCTS_SUCCESS:
      return {
        ...state,
        companyProducts: action.data,
        loadingCompanyProducts: false
      };
    case actionTypes.GET_COMPANY_PRODUCTS_FAIL:
      return  {
        ...state,
        loadingCompanyProducts: false
      };


    case actionTypes.GET_PRODUCT_CATEGORIES_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.data,
        loadingCategories: false
      };
    case actionTypes.GET_PRODUCT_CATEGORIES_FAIL:
      return  {
        ...state,
        loadingCategories: false
      };

    case actionTypes.CREATE_PRODUCT_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.CREATE_PRODUCT_CATEGORY_SUCCESS:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_PRODUCT_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.UPDATE_PRODUCT_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_PRODUCT_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false,
        notification: { status: true, type: 'error', message: action.data }
      };

    case actionTypes.DELETE_PRODUCT_CATEGORY_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.DELETE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loadingCategories: false
      };
    case actionTypes.DELETE_PRODUCT_CATEGORY_FAIL:
      return  {
        ...state,
        loadingCategories: false
      };


    default:
      return state;
  }
}

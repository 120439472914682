import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {HighlightOff, TaskAlt} from "@mui/icons-material";
import {useLocation} from "react-router-dom";

import i18n from "../../i18n";
import brochure from '../../assets/images/borchure.png';
import companyPlaceholder from '../../assets/images/company_placeholder.png';
import icon from '../../assets/images/lauf.png';
import {LoaderSpinner} from "../../components/Common/Loader";
import {getCompanyServices} from "../../store/actions/service";
import './index.scss';


const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {state} = useLocation();
  const {companyServices, loadingCompanyServices} = useSelector( state => state.service);

  const currentLang = i18n.language || 'is';
  const [activeCategory, setActiveCategory] = useState(0);

  useEffect(() => {
    dispatch(getCompanyServices());
  }, []);

  useEffect(() => {
    if(companyServices && companyServices.results && state && state.showServiceCategory) {
      let findItemIndex = companyServices.results.findIndex(f => f.id === state.serviceId);
      if(findItemIndex>= 0) {
        setActiveCategory(findItemIndex)
      }
    }
  }, [companyServices, state])

  if (loadingCompanyServices) return (<LoaderSpinner/>);

  return (
    <div className="servicePage">

      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-10 col-xxl-10">
          <div className="header">
            <div className="headerBoxes">
              {
                companyServices.results && companyServices.results.map((category, categoryIndex) => {
                  let catImg = category.icon ? category.icon : icon;
                  return (
                    <div
                      className={`box ${activeCategory === categoryIndex && 'active'}`}
                      onClick={() => setActiveCategory(categoryIndex)}
                      key={"productsPage_headerCategory_"+categoryIndex}
                    >
                      <div className="icon" style={{backgroundImage: "url(" + catImg + ")"}}/>
                      <div className="copy">{category["title_"+currentLang] ? category["title_"+currentLang] : category.title}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>

      { companyServices.results &&
        companyServices.results[activeCategory] &&
        companyServices.results[activeCategory].sub_categories.length>0 &&
        companyServices.results[activeCategory].companies.length>0 &&
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

              <div className="productsContent">
                <div className="categories">
                  {
                    companyServices.results[activeCategory].sub_categories.map((subCategory, subCategoryIndex) =>
                      <div className="category" key={"productsPage_subCategory_" + subCategoryIndex}>
                        {subCategory["title_"+currentLang] ? subCategory["title_"+currentLang] : subCategory.title}
                      </div>
                    )
                  }
                </div>

                {
                  companyServices.results[activeCategory].companies.map((company, companyIndex) => {
                      let companyImg = company.logo ? company.logo : companyPlaceholder
                      return (
                        <div className="companyCard" key={"productsPage_company_"+companyIndex}>
                          <div className="logo">
                            <div className="companyImg" style={{backgroundImage: "url(" + companyImg + ")"}}/>
                            <div className="companyName"> {company.name} </div>
                          </div>
                          {
                            companyServices.results[activeCategory].sub_categories.map((category, categoryIndex) => {
                              let hasProduct = company.service_category_nxn.findIndex(f => f.service_category_id === category.id)
                              return hasProduct >= 0 ? <div className="companyProduct active"><TaskAlt/></div>
                                : <div className="companyProduct"><HighlightOff/></div>
                            })
                          }
                          {company.brochure &&
                            <div className="brochure">
                              <a href={company.brochure} target="_blank" rel="noreferrer">
                                <div className="brochureImg" style={{backgroundImage: "url(" + brochure + ")"}}/>
                              </a>
                            </div>
                          }
                          {company.link &&
                            <a href={company.link} target="_blank" rel="noreferrer" className="cta">{t('link')}</a>
                          }
                        </div>
                      )
                    })
                }
              </div>

            </div>
          </div>
      }

    </div>
  )
};

export default Index
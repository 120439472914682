import React, {useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Table,  Card, CardBody, Button} from "reactstrap"
import {Edit} from '@mui/icons-material';

import AddCategory from "./addCategory";
import {getCompanyCategories, createUpdateCompanyCategory, deleteCompanyCategory} from "../../store/actions/companyCategory";
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"
import i18n from "../../i18n";

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const [alert, setAlert] = React.useState(null);
  const {categories, loadingCategories} = useSelector( state => state.companyCategory);
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canCreate = permission.includes('admin_company_categories_create');
  const canEdit = permission.includes('admin_company_categories_edit');
  const canDelete = permission.includes('admin_company_categories_delete');
  const currentLang = i18n.language || 'is';

  useEffect(() => {
    dispatch(getCompanyCategories());
  }, []);

  if (loadingCategories) return (<LoaderSpinner/>);

  const onSubmit = (data, update) => {
    dispatch(createUpdateCompanyCategory({update:update, ...data}));
  };

  const htmlAlert = (data) => {
    setAlert(
      <AddCategory data={data} categories={categories.results} onCancel={() => setAlert(null)} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  return (
    <div className="page-content">
      {alert}
      <Card>
        <CardBody>
          <div className="row">
            <div className="col col-12 float-end">
              {canCreate && <Button color="success" className="mb-4" size="sm" style={{float: 'right'}} onClick={htmlAlert}>{t('category_page.add_new')}</Button>}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>{t('category_page.icon')}</th>
                  <th>{t('category_page.title')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!categories.results ? '' :
                  categories.results.map((value, index) => {
                    let rows = [<tr key={index + '_parent'} className='table-success'>
                      <td>{index + 1}</td>
                      <td><img className='img-thumbnail rounded category_img' src={value.logo} alt=""/></td>
                      <td>{value["title_" + currentLang] ? value["title_" + currentLang] : value.title}</td>
                      <td>
                        {canEdit &&
                          <Button key={value.id + 'type'} color="success" size="sm" onClick={() => htmlAlert(value)}>
                            <Edit style={{fontSize: '12px'}}/>
                          </Button>
                        }
                        {canDelete &&
                          <DeleteButton
                            key={value.id + '_delete'}
                            action={() => dispatch(deleteCompanyCategory({...value}))}
                          />
                        }
                      </td>
                    </tr>];
                    value.sub_categories.map((sValue, sIndex) => {
                      rows.push(<tr key={sIndex + 1}>
                        <td>{index + 1}.{sIndex + 1}</td>
                        <td><img className="img-thumbnail rounded category_img" src={sValue.logo} alt=""/></td>
                        <td>{sValue["title_" + currentLang] ? sValue["title_" + currentLang] : sValue.title}</td>
                        <td>
                          {canEdit &&
                            <Button key={sValue.id + 'type'} color="success" size="sm" onClick={() => htmlAlert(sValue)}>
                              <Edit style={{fontSize: '12px'}}/>
                            </Button>
                          }
                          {canDelete &&
                            <DeleteButton
                              key={value.id + '_delete'}
                              action={() => dispatch(deleteCompanyCategory({...sValue}))}
                            />
                          }
                        </td>
                      </tr>)
                    });
                    return rows;
                  })
                }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default Index

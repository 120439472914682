import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Modal, Input, Label} from "reactstrap"
import Swal from "sweetalert";
import Select from "react-select";
import Switch from "react-switch";
import {customDropdownSmallStyles, customDropdownStyles, customDropdownWithLogo} from "../../helpers/dropdown";
import {getCompanyCategories} from "../../store/actions/companyCategory";
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"
import {getProductCategories} from "../../store/actions/products";
import FileUploadPdf from "../../components/Common/CustomUpload/FileUploadPdf";
import {getServiceCategories} from "../../store/actions/service";

const companySizes = [
  {label: 'Micro <10', value: 1}, {label: 'Lítil 10-50', value: 2},
  {label: 'Mið 50-250', value: 3}, {label: 'Stór 250+', value: 4}
];

const AddCompany = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {categories, loadingCategories} = useSelector( state => state.companyCategory);
  const {categories:productCategories, loadingCategories:loadingProductCategories} = useSelector( state => state.product);
  const {categories:serviceCategories, loadingCategories:loadingServiceCategories} = useSelector( state => state.service);
  const [errorStatus, setErrorStatus] = useState(false);
  const [state, setState] = useState({
    id: props.data.id || null,
    name: props.data.name || "",
    nameValid: !props.data.name,
    kennitala: props.data.kennitala || "",
    kennitalaValid: !props.data.kennitala,
    website: props.data.website || "",

    contact_name: props.data.contact_name || "",
    contact_email: props.data.contact_email || "",
    description: props.data.description || "",
    visible: props.data.visible !== null ? props.data.visible : true,
    company_size: props.data.company_size || 1,
    company_category_id: props.data.company_category_id || "",
    categoryOption: {},

    product_categories: [],
    service_categories: [],
    link: props.data.link || "",
    brochure: props.data.brochure || null
  });
  const [productCategoriesList, setProductCategoriesList] = useState(props.data.product_category_nxn && props.data.product_category_nxn.length>0 ? props.data.product_category_nxn.filter(f => f.product_category).map(item => { return {label: item.product_category.title, value: item.product_category.id}}) : []);
  const [serviceCategoriesList, setServiceCategoriesList] = useState(props.data.service_category_nxn && props.data.service_category_nxn.length>0 ? props.data.service_category_nxn.filter(f => f.service_category).map(item => { return {label: item.service_category.title, value: item.service_category.id}}) : []);



  useEffect(() => {
    dispatch(getCompanyCategories());
    categories.length === 0 && dispatch(getProductCategories());
    serviceCategories.length === 0 && dispatch(getServiceCategories());
  }, []);

  useEffect(() => {
    if(categories && categories.results && props.data.company_category_id) {
      let category = false;
      categories.results.forEach(item => {
        let findCategory = item.sub_categories.find(f => f.id === props.data.company_category_id)
        if(findCategory) {
          category = item.sub_categories.find(f => f.id === props.data.company_category_id);
        }
      })
      if(category) {
        setState({...state, categoryOption: {label: category.title, value: category.id, logo: category.logo}})
      }
    }
  }, [categories]);

  const isValidated = () => {
    let data = {...state};
    data['nameValid'] = state.name.length > 3;
    data['kennitalaValid'] = (/^[0-9]+$/i.test(state.kennitala));
    setState(data);

    if(data.nameValid && data.kennitalaValid) {
      return true
    } else {
      Swal({text:'Vinsamlega fylltu út í alla nauðsynlega textareiti', icon: "warning", timer: 4000, buttons: false});
      setErrorStatus(true);
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      if(productCategoriesList) {
        state['product_categories'] = productCategoriesList.map(item => item.value)
      }
      if(serviceCategoriesList) {
        state['service_categories'] = serviceCategoriesList.map(item => item.value)
      }


      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const onCancel = () => {
    props.onCancel();
  };

  const changePdf= (value, fileName) => {
    let copyState = {...state};
    copyState['brochure'] = value;
    setState(copyState);
  };

  if (loadingCategories || loadingProductCategories || loadingServiceCategories) return (
    <Modal isOpen={true} centered toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header"> <h5 className="modal-title">{state.id ? t('company_page.update') : t('company_page.add_new')}</h5> </div>
      <div className="modal-body"> <LoaderSpinner/> </div>
    </Modal>
  );

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"} size="lg" style={{maxWidth: '1100px', width: '100%'}}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('company_page.update') : t('company_page.add_new')}</h5>
      </div>
      <div className="modal-body companyAdminPageAdd">
        <div className="row mb-4">
          <div className="col-3">
            <label className="form-label">{t('company_page.name')} <small>({t('required')})</small></label>
            <Input
              id="name"
              name="name"
              type="text"
              value={state.name}
              className="form-control"
              onChange={event => change(event, 'name')}
              invalid={errorStatus && !state.nameValid}
            />
          </div>
          <div className="col-3">
            <label className="form-label">{t('company_page.kennitala')} <small>({t('required')})</small></label>
            <Input
              id="kennitala"
              name="kennitala"
              type="text"
              value={state.kennitala}
              className="form-control"
              onChange={event => change(event, 'kennitala')}
              invalid={errorStatus && !state.kennitalaValid}
            />
          </div>
          <div className="col-3">
            <label className="form-label">{t('settings_page.website')}</label>
            <Input
              id="website"
              name="website"
              type="text"
              value={state.website}
              className="form-control"
              onChange={event => change(event, 'website')}
            />
          </div>
          <div className="col-3 toggleColumn">
            <label className="col-form-label">{t('company_page.visible')}</label>
            <Switch
              uncheckedIcon={<div className="swithStyle">{" "}</div>}
              checkedIcon={<div className="swithStyle">{" "}</div>}
              onColor="#009369"
              className="form-label leaf_item_checkbox"
              onChange={() => setState({...state, visible: !state.visible})}
              checked={state.visible}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-3">
            <label className="form-label">{t('onboarding_page.category')}</label>
            <Select
              isSearchable={false}
              placeholder={t('user_page.role')}
              styles={customDropdownWithLogo}
              value={state.categoryOption || {}}
              onChange={(event) =>
                setState({
                  ...state,
                  company_category_id: event.value,
                  categoryOption: event
                })
              }
              options={categories.results ? categories.results.map(item => {
                  return {
                    label: item.title, logo: item.logo, options: item.sub_categories.map(cat => {
                      return {label: cat.title, value: cat.id, logo: cat.logo}
                    })
                  }
                })
                : []}
              formatOptionLabel={item => Object.keys(item).length > 0 ? (
                <div className="categoriesOption">
                  <img src={item.logo} alt="category-image"/>
                  <span>{item.label}</span>
                </div>
              ) : null}
              className={`react-select ${errorStatus && state.company_category_id === 0 && 'has-error'}`}
            />
          </div>

          <div className="col-3">
            <label className="form-label">{t('onboarding_page.company_size')}</label>
            <Select
              isSearchable={false}
              styles={customDropdownSmallStyles}
              value={companySizes.find(f => f.value === state.company_size)}
              onChange={event => setState({...state, company_size: event.value})}
              options={companySizes}
              className={`react-select`}
            />
          </div>

          <div className="col-3">
            <label className="form-label">{t('settings_page.contact_person')}</label>
            <Input
              name="contact_name"
              type="text"
              value={state.contact_name}
              className="form-control"
              onChange={event => change(event, 'contact_name')}
            />
          </div>

          <div className="col-3">
            <label className="form-label">{t('settings_page.contact_email')}</label>
            <Input
              name="contact_email"
              type="text"
              value={state.contact_email}
              className="form-control"
              onChange={event => change(event, 'contact_email')}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <label className="form-label">{t('pluses_page.description')}</label>
            <Input
              name="description"
              type="text"
              value={state.description}
              className="form-control"
              onChange={event => change(event, 'description')}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col col-5">
            <Label className="col-form-label">{t('product-categories')}</Label>
            <Select
              key={'gs_categories'}
              isMulti
              value={productCategoriesList}
              placeholder={t('plan_page.select')}
              styles={customDropdownStyles()}
              className={`react-select ${state.selectError && 'has-error'}`}
              onChange={(event) => setProductCategoriesList(event)}
              options={
                productCategories.results && productCategories.results.map((item, itemIndex) => {
                  return {
                    label: item.title,
                    options: item.sub_categories.map((item2, item2Index) => {
                      return {label: item2.title, value: item2.id}
                    })
                  }
                })
              }
            />

          </div>

          <div className="col-4 linkColumn">
            <label className="form-label">Link</label>
            <Input
              id="name"
              name="name"
              type="text"
              value={state.link}
              className="form-control"
              onChange={event => change(event, 'link')}
            />
          </div>
          <div class="col-3 pdfColumn">
            <label className="form-label">Brochure</label>
            <FileUploadPdf onUpload={(data) => changePdf(data)} msg={'Upload brochure'}/>
            {state.brochure &&
              <div class="brochureFile">
                <a href={state.brochure} target="_blank" rel="noreferrer">
                  Uploaded brochure File
                </a>
              </div>
            }
          </div>
        </div>

        <div className="row mb-4">
          <div className="col col-5">
            <Label className="col-form-label">{t('service-categories')}</Label>
            <Select
              key={'gs_categories'}
              isMulti
              value={serviceCategoriesList}
              placeholder={t('plan_page.select')}
              styles={customDropdownStyles()}
              className={`react-select ${state.selectError && 'has-error'}`}
              onChange={(event) => setServiceCategoriesList(event)}
              options={
                serviceCategories.results && serviceCategories.results.map((item, itemIndex) => {
                  return {
                    label: item.title,
                    options: item.sub_categories.map((item2, item2Index) => {
                      return {label: item2.title, value: item2.id}
                    })
                  }
                })
              }
            />
          </div>

        </div>

      </div>

      <div className="modal-footer">
      <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddCompany